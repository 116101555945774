import * as i from 'types';
import React from 'react';
import { graphql } from 'gatsby';

import { Template } from 'common/layout';
import { ContentArea } from 'common/contentarea';

const ConceptPage: React.FC<ConceptPageProps> = ({ data }) => {
  const concept = data?.contentfulTypeConcept;
  const body = concept?.body as i.ContentAreaBlocksTypes[] || [];

  if (!concept) return null;

  return (
    <Template>
      <ContentArea contentTypes={body} />
    </Template>
  );
};

export default ConceptPage;

export const query = graphql`
  query ConceptPage($id: String, $language: String) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          language
          data
        }
      }
    }
    contentfulTypeConcept(id: {eq: $id}, node_locale: {eq: $language}) {
      id
      title
      subtitle
      slug
      body {
        __typename
        ...PageBodyFragment
      }
    }
  }
`;

type ConceptPageProps = {
  data: GatsbyTypes.ConceptPageQuery;
};
